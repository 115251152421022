<template>
  <v-dialog v-model="dialogShow" width="800px" content-class="more-history customEditModal company">
    <v-card class="pa-5">
      <h1>More History</h1>

      <div class="mt-10 content">
        <v-data-table
          hide-default-footer
          disable-sort
          :headers="headers"
          :items="works"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :no-data-text="$t('common.text.noData')"
          :no-results-text="$t('common.text.noMatchingResult')">
          <template v-slot:[`item.type`]="{ item }">
            <v-chip label :class="item.type === 'RCV_PO' ? 'PO' : item.type">
              {{ `${item.type === "RCV_PO" ? "PO" : item.type}` }}
            </v-chip>
            <history-sentence :history="item" @deletedHistoryClicked="msg => $emit('deletedHistoryClicked', msg)" />
          </template>
          <template v-slot:[`item.sysRegDt`]="{ item }">
            {{ $m(item.sysRegDt).format("yyyy-MM-DD HH:mm") }}
          </template>
        </v-data-table>

        <div class="text-center pt-2" v-if="pageCount > 0">
          <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
        </div>

        <div class="actions d-flex justify-center">
          <v-btn color="secondary" dark :elevation="0" @click="dialogShow = false">OK</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { hasValue, numberWithComma } from "@/utils/util";
import HistorySentence from "./HistorySentence.vue";
export default {
  components: {
    HistorySentence,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    works: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      headers: [
        { text: "WORKS", value: "type" },
        { text: "WORKED BY", value: "regUser", align: "center", cellClass: "font-gray2" },
        { text: "DATE", value: "sysRegDt", align: "center", cellClass: "font-gray2" },
      ],
      page: 1,
      itemsPerPage: 10,
      pageCount: 0,
    };
  },
  methods: {
    init() {
      this.page = 1;
    },
    hasValue,
    numberWithComma,
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    works() {
      this.init();
    },
  },
};
</script>

<style></style>

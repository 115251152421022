var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"display":"inline-block"}},[(_vm.history.type === 'Material')?[_c('span',[_vm._v(" "+_vm._s(("Added " + (_vm.history.prefix) + " " + (_vm.history.prefix.toString() === "1" ? "Material" : "Materials")))+" "),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"font-blue1 pointer hoverUnderline"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.history.suffix))])]}}],null,false,3802120623),model:{value:(_vm.menuShow),callback:function ($$v) {_vm.menuShow=$$v},expression:"menuShow"}},[_c('company-info',{attrs:{"cmpyUUID":_vm.history.cmpyUUID}})],1)],1)]:_vm._e(),(_vm.history.type === 'BOM')?[(['Created', 'Modified'].includes(_vm.history.workType))?_c('span',[_vm._v(" "+_vm._s(((_vm.history.workType) + " "))+" "),_c('span',{staticClass:"font-error pointer hoverUnderline",on:{"click":function($event){_vm.linkClicked(
            _vm.$router.resolve({
              name: 'BOM',
              query: {
                bomUUID: _vm.history.targetUUID,
              },
            }).href
          )}}},[_vm._v(" "+_vm._s(((_vm.history.prefix) + " #" + (_vm.history.suffix)))+" ")])]):_c('span',[_c('span',{staticClass:"font-error pointer hoverUnderline",on:{"click":function($event){_vm.linkClicked(
            _vm.$router.resolve({
              name: 'BOM',
              query: {
                bomUUID: _vm.history.targetUUID,
              },
            }).href
          )}}},[_vm._v(" "+_vm._s(((_vm.history.prefix) + " #" + (_vm.history.suffix)))+" ")]),_vm._v(" "+_vm._s(("status has changed to " + (_vm.history.workType === "Progress" ? "in Progress" : _vm.history.workType)))+" ")])]:_vm._e(),(_vm.history.type === 'CBD')?[(['Created', 'Modified'].includes(_vm.history.workType))?_c('span',[_vm._v(" "+_vm._s(((_vm.history.workType) + " "))+" "),_c('span',{staticClass:"font-error pointer hoverUnderline",on:{"click":function($event){_vm.linkClicked(
            _vm.$router.resolve({
              name: 'CBD',
              params: {
                cbdUUID: _vm.history.targetUUID,
              },
            }).href
          )}}},[_vm._v(" "+_vm._s(((_vm.history.prefix) + " #" + (_vm.history.suffix)))+" ")])]):_c('span',[_c('span',{staticClass:"font-error pointer hoverUnderline",on:{"click":function($event){_vm.linkClicked(
            _vm.$router.resolve({
              name: 'CBD',
              params: {
                cbdUUID: _vm.history.targetUUID,
              },
            }).href
          )}}},[_vm._v(" "+_vm._s(((_vm.history.prefix) + " #" + (_vm.history.suffix)))+" ")]),_vm._v(" "+_vm._s(("status has changed to " + (_vm.history.workType)))+" ")])]:_vm._e(),(_vm.history.type === 'MCL')?[(['Created', 'Modified'].includes(_vm.history.workType))?_c('span',[_vm._v(" "+_vm._s(((_vm.history.workType) + " "))+" "),_c('span',{staticClass:"font-error pointer hoverUnderline",on:{"click":function($event){_vm.linkClicked(
            _vm.$router.resolve({
              name: 'MCL',
              params: {
                mclUUID: _vm.history.targetUUID,
              },
            }).href
          )}}},[_vm._v(" "+_vm._s(((_vm.history.prefix) + " #" + (_vm.history.suffix)))+" ")])]):_c('span',[_c('span',{staticClass:"font-error pointer hoverUnderline",on:{"click":function($event){_vm.linkClicked(
            _vm.$router.resolve({
              name: 'MCL',
              params: {
                mclUUID: _vm.history.targetUUID,
              },
            }).href
          )}}},[_vm._v(" "+_vm._s(((_vm.history.prefix) + " #" + (_vm.history.suffix)))+" ")]),_vm._v(" "+_vm._s(("status has changed to " + (_vm.history.workType)))+" ")])]:_vm._e(),(_vm.history.type === 'PO')?[(['Created'].includes(_vm.history.workType))?_c('span',[_vm._v(" "+_vm._s("Create a")+" "),_c('span',{staticClass:"font-error pointer hoverUnderline",on:{"click":function($event){_vm.linkClicked(
            _vm.$router.resolve({
              name: 'issuedRMPODetail',
              params: {
                poUUID: _vm.history.targetUUID,
              },
            }).href
          )}}},[_vm._v(" "+_vm._s("new draft PO")+" ")])]):_vm._e(),(['Modified'].includes(_vm.history.workType))?_c('span',[_vm._v(" "+_vm._s(((_vm.history.workType) + " "))+" "),_c('span',{staticClass:"font-error pointer hoverUnderline",on:{"click":function($event){_vm.linkClicked(
            _vm.$router.resolve({
              name: 'issuedRMPODetail',
              params: {
                poUUID: _vm.history.targetUUID,
              },
            }).href
          )}}},[_vm._v(" "+_vm._s(("" + (_vm.history.prefix || "draft PO")))+" ")])]):_vm._e(),(['Published'].includes(_vm.history.workType))?_c('span',[_vm._v(" "+_vm._s(("" + (_vm.history.workType)))+" "),_c('span',{staticClass:"font-error pointer hoverUnderline",on:{"click":function($event){_vm.linkClicked(
            _vm.$router.resolve({
              name: 'issuedRMPODetail',
              params: {
                poUUID: _vm.history.targetUUID,
              },
            }).href
          )}}},[_vm._v(" "+_vm._s(("" + (_vm.history.prefix)))+" ")]),_vm._v(" "+_vm._s(" on ")+" "),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"font-blue1 pointer hoverUnderline"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.history.suffix))])]}}],null,false,3802120623),model:{value:(_vm.menuShow),callback:function ($$v) {_vm.menuShow=$$v},expression:"menuShow"}},[_c('company-info',{attrs:{"cmpyUUID":_vm.history.cmpyUUID}})],1)],1):_vm._e(),(['Retrived', 'Re-Published'].includes(_vm.history.workType))?_c('span',[_vm._v(" "+_vm._s(("" + (_vm.history.workType)))+" "),_c('span',{staticClass:"font-error pointer hoverUnderline",on:{"click":function($event){_vm.linkClicked(
            _vm.$router.resolve({
              name: 'issuedRMPODetail',
              params: {
                poUUID: _vm.history.targetUUID,
              },
            }).href
          )}}},[_vm._v(" "+_vm._s(("" + (_vm.history.prefix)))+" ")])]):_vm._e()]:_vm._e(),(_vm.history.type === 'RCV_PO')?[(['Received', 'Retrived'].includes(_vm.history.workType))?_c('span',[_vm._v(" "+_vm._s(("" + (_vm.history.workType)))+" "),_c('span',{staticClass:"font-error pointer hoverUnderline",on:{"click":function($event){_vm.linkClicked(
            _vm.$router.resolve({
              name: 'receivedRMPODetail',
              params: {
                poUUID: _vm.history.targetUUID,
              },
            }).href
          )}}},[_vm._v(" "+_vm._s(("" + (_vm.history.prefix)))+" ")]),_vm._v(" "+_vm._s(" by ")+" "),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"font-blue1 pointer hoverUnderline"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.history.suffix))])]}}],null,false,3802120623),model:{value:(_vm.menuShow),callback:function ($$v) {_vm.menuShow=$$v},expression:"menuShow"}},[_c('company-info',{attrs:{"cmpyUUID":_vm.history.cmpyUUID}})],1)],1):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
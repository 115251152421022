<template>
  <v-dialog v-model="dialogShow" width="400px" content-class="alert customEditModal">
    <v-card class="pa-5">
      <div class="content text-center">
        <slot></slot>
      </div>

      <div class="actions d-flex justify-center">
        <v-btn color="secondary" dark :elevation="0" @click="okClicked">
          {{ okButton && hasValue(okButton.text) ? okButton.text : "OK" }}
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { hasValue } from "@/utils/util";
export default {
  props: ["show", "okButton", "hideEvent"],
  data() {
    return {};
  },
  methods: {
    okClicked() {
      this.$emit("ok", this.okButton?.params);
      this.dialogShow = false;
    },
    hasValue,
  },
  computed: {
    dialogShow: {
      get() {
        return this.show;
      },
      set(value) {
        if (this.hideEvent === true && value === false) this.$emit("hide", this.okButton?.params);
        this.$emit("update:show", value);
      },
    },
  },
};
</script>

<style></style>

<template>
  <v-card outlined flat rounded="xl" class="fh">
    <div class="d-flex pa-8 fh">
      <full-calendar :options="calendarOptions">
        <template #eventContent="arg">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div style="min-height: 20px; width: 100%" v-bind="attrs" v-on="on" v-text="arg.event.title"></div>
            </template>
            <history-sentence
              :history="arg.event.extendedProps"
              @deletedHistoryClicked="msg => $emit('deletedHistoryClicked', msg)" />
          </v-tooltip>
        </template>
      </full-calendar>
    </div>

    <more-history
      :works="moreWorks"
      :show.sync="moreWorkShow"
      @deletedHistoryClicked="msg => $emit('deletedHistoryClicked', msg)" />
  </v-card>
</template>

<script>
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import MoreHistory from "./MoreHistory.vue";
import HistorySentence from "./HistorySentence.vue";

export default {
  props: ["works"],
  components: {
    FullCalendar,
    MoreHistory,
    HistorySentence,
  },
  data() {
    return {
      moreWorkShow: false,
      moreWorks: [],
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: "dayGridMonth",
        events: [],
        dayMaxEvents: 2,
        moreLinkClick: info => {
          this.moreWorks = info.allSegs.map(i => ({
            ...i.event.extendedProps,
          }));
          this.moreWorkShow = true;
          return 1;
        },
      },
    };
  },
  watch: {
    works(newVal) {
      this.calendarOptions.events = (newVal || []).map(w => ({
        title: w.type === "RCV_PO" ? "PO" : w.type,
        start: w.sysRegDt,
        classNames: [w.type === "RCV_PO" ? "PO" : w.type],
        extendedProps: w,
      }));
    },
  },
};
</script>

<style></style>

<template>
  <v-card class="company-popover" min-width="350px">
    <v-card-title>
      <h2 class="font-secondary">{{ company.name }}</h2>
    </v-card-title>
    <v-card-subtitle> Primary Business: {{ company.typeName }} </v-card-subtitle>

    <v-divider class="hr-1" />

    <v-card-text>
      <div v-for="(popoverItem, index) in popoverInItems" :key="index" class="list-item">
        <div class="d-flex">
          <p class="label">
            {{ popoverItem.text }}
          </p>
          <p class="value">
            <template v-if="popoverItem.key === 'address'">
              {{ hasValue(company.cityCode) ? `${company.address}, ${company.cityCode}` : `${company.address}` }}
            </template>
            <template v-else>
              {{ company[popoverItem.key] }}
            </template>
          </p>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import codeHandler from "@/utils/codeHandler";
import { hasValue } from "@/utils/util";
export default {
  props: ["cmpyUUID", "cmpy"],
  data() {
    return {
      company: {},
      popoverInItems: [
        { text: "Country", key: "countryCode" },
        {
          text: "Address",
          key: "address",
        },
        {
          text: "Contact E-mail",
          key: "contactMail",
        },
        {
          text: "Contact Call",
          key: "contactCall",
        },
        {
          text: "Member Name",
          key: "contactName",
        },
      ],
    };
  },
  methods: {
    hasValue,
  },
  watch: {
    cmpy(newVal) {
      if (hasValue(newVal)) this.company = newVal;
    },
  },
  created() {
    if (hasValue(this.cmpyUUID))
      this.$http.get("/company", { data: { cmpyUUID: this.cmpyUUID } }).then(res => {
        if (res.data.status === 200) {
          this.company = res.data.data;
          this.company.contactCall = hasValue(this.company.contactCallCountry)
            ? `${this.company.contactCallCountry} ${this.company.contactCall}`
            : this.company.contactCall;
        } else codeHandler(res.data);
      });
    else if (hasValue(this.cmpy)) this.company = this.cmpy;
  },
};
</script>

<style></style>

<template>
  <div style="display: inline-block">
    <!-- #region Material -->
    <template v-if="history.type === 'Material'">
      <span>
        {{ `Added ${history.prefix} ${history.prefix.toString() === "1" ? "Material" : "Materials"}` }}

        <v-menu v-model="menuShow" :close-on-content-click="false" :nudge-width="200" offset-x>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="font-blue1 pointer hoverUnderline">{{ history.suffix }}</span>
          </template>

          <company-info :cmpyUUID="history.cmpyUUID" />
        </v-menu>
      </span>
    </template>
    <!-- #endregion -->

    <!-- #region BOM -->
    <template v-if="history.type === 'BOM'">
      <span v-if="['Created', 'Modified'].includes(history.workType)">
        {{ `${history.workType} ` }}
        <span
          class="font-error pointer hoverUnderline"
          @click="
            linkClicked(
              $router.resolve({
                name: 'BOM',
                query: {
                  bomUUID: history.targetUUID,
                },
              }).href,
            )
          ">
          {{ `${history.prefix} #${history.suffix}` }}
        </span>
      </span>

      <span v-else>
        <span
          class="font-error pointer hoverUnderline"
          @click="
            linkClicked(
              $router.resolve({
                name: 'BOM',
                query: {
                  bomUUID: history.targetUUID,
                },
              }).href,
            )
          ">
          {{ `${history.prefix} #${history.suffix}` }}
        </span>
        {{
          `status has changed to
            ${history.workType === "Progress" ? "in Progress" : history.workType}`
        }}
      </span>
    </template>
    <!-- #endregion -->

    <!-- #region CBD -->
    <template v-if="history.type === 'CBD'">
      <span v-if="['Created', 'Modified'].includes(history.workType)">
        {{ `${history.workType} ` }}
        <span
          class="font-error pointer hoverUnderline"
          @click="
            linkClicked(
              $router.resolve({
                name: 'CBD',
                params: {
                  cbdUUID: history.targetUUID,
                },
              }).href,
            )
          ">
          {{ `${history.prefix} #${history.suffix}` }}
        </span>
      </span>

      <span v-else>
        <span
          class="font-error pointer hoverUnderline"
          @click="
            linkClicked(
              $router.resolve({
                name: 'CBD',
                params: {
                  cbdUUID: history.targetUUID,
                },
              }).href,
            )
          ">
          {{ `${history.prefix} #${history.suffix}` }}
        </span>
        {{ `status has changed to ${history.workType}` }}
      </span>
    </template>
    <!-- #endregion -->

    <!-- #region MCL -->
    <template v-if="history.type === 'MCL'">
      <span v-if="['Created', 'Modified'].includes(history.workType)">
        {{ `${history.workType} ` }}
        <span
          class="font-error pointer hoverUnderline"
          @click="
            linkClicked(
              $router.resolve({
                name: 'MCL',
                params: {
                  mclUUID: history.targetUUID,
                },
              }).href,
            )
          ">
          {{ `${history.prefix} #${history.suffix}` }}
        </span>
      </span>

      <span v-else>
        <span
          class="font-error pointer hoverUnderline"
          @click="
            linkClicked(
              $router.resolve({
                name: 'MCL',
                params: {
                  mclUUID: history.targetUUID,
                },
              }).href,
            )
          ">
          {{ `${history.prefix} #${history.suffix}` }}
        </span>
        {{ `status has changed to ${history.workType}` }}
      </span>
    </template>
    <!-- #endregion -->

    <!-- #region PO -->
    <template v-if="history.type === 'PO'">
      <span v-if="['Created'].includes(history.workType)">
        {{ `Create a` }}
        <span
          class="font-error pointer hoverUnderline"
          @click="
            linkClicked(
              $router.resolve({
                name: 'issuedRMPODetail',
                params: {
                  poUUID: history.targetUUID,
                },
              }).href,
            )
          ">
          <!-- {{ `${prefix || "new draft PO"}` }} -->
          {{ `new draft PO` }}
        </span>
      </span>

      <span v-if="['Modified'].includes(history.workType)">
        {{ `${history.workType} ` }}
        <span
          class="font-error pointer hoverUnderline"
          @click="
            linkClicked(
              $router.resolve({
                name: 'issuedRMPODetail',
                params: {
                  poUUID: history.targetUUID,
                },
              }).href,
            )
          ">
          {{ `${history.prefix || "draft PO"}` }}
        </span>
      </span>

      <span v-if="['Published'].includes(history.workType)">
        {{ `${history.workType}` }}
        <span
          class="font-error pointer hoverUnderline"
          @click="
            linkClicked(
              $router.resolve({
                name: 'issuedRMPODetail',
                params: {
                  poUUID: history.targetUUID,
                },
              }).href,
            )
          ">
          {{ `${history.prefix}` }}
        </span>
        {{ ` on ` }}
        <v-menu v-model="menuShow" :close-on-content-click="false" :nudge-width="200" offset-x>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="font-blue1 pointer hoverUnderline">{{ history.suffix }}</span>
          </template>

          <company-info :cmpyUUID="history.cmpyUUID" />
        </v-menu>
      </span>

      <span v-if="['Retrived', 'Re-Published'].includes(history.workType)">
        {{ `${history.workType}` }}
        <span
          class="font-error pointer hoverUnderline"
          @click="
            linkClicked(
              $router.resolve({
                name: 'issuedRMPODetail',
                params: {
                  poUUID: history.targetUUID,
                },
              }).href,
            )
          ">
          {{ `${history.prefix}` }}
        </span>
      </span>
    </template>
    <!-- #endregion -->

    <!-- #region RCV_PO -->
    <template v-if="history.type === 'RCV_PO'">
      <span v-if="['Received', 'Retrived'].includes(history.workType)">
        {{ `${history.workType}` }}
        <span
          class="font-error pointer hoverUnderline"
          @click="
            linkClicked(
              $router.resolve({
                name: 'receivedRMPODetail',
                params: {
                  poUUID: history.targetUUID,
                },
              }).href,
            )
          ">
          {{ `${history.prefix}` }}
        </span>
        {{ ` by ` }}
        <v-menu v-model="menuShow" :close-on-content-click="false" :nudge-width="200" offset-x>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on" class="font-blue1 pointer hoverUnderline">{{ history.suffix }}</span>
          </template>

          <company-info :cmpyUUID="history.cmpyUUID" />
        </v-menu>
      </span>
    </template>
    <!-- #endregion -->
  </div>
</template>

<script>
import CompanyInfo from "@/components/common/popover/CompanyInfo.vue";
export default {
  components: { CompanyInfo },
  props: {
    history: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      menuShow: false,
    };
  },
  methods: {
    linkClicked(link) {
      if (this.history.isDeleted === "Y")
        this.$emit(
          "deletedHistoryClicked",
          `This ${this.history.type === "RCV_PO" ? "PO" : this.history.type} has been discarded`,
        );
      else window.open(link, "_blank");
    },
  },
};
</script>

<style></style>

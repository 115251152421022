<template>
  <v-card outlined flat rounded="xl">
    <div class="pa-8">
      <div class="d-flex head">
        <h1>Latest Update</h1>
        <v-tabs v-model="tab">
          <v-tab>All</v-tab>
          <v-tab>My</v-tab>
        </v-tabs>
      </div>
      <div class="contents">
        <v-data-table
          hide-default-footer
          disable-sort
          :headers="headers"
          :items="filteredWorks"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          :no-data-text="$t('common.text.noData')"
          :no-results-text="$t('common.text.noMatchingResult')">
          <template v-slot:[`item.type`]="{ item }">
            <v-chip label :class="item.type === 'RCV_PO' ? 'PO' : item.type">
              {{ `${item.type === "RCV_PO" ? "PO" : item.type}` }}
            </v-chip>

            <history-sentence :history="item" @deletedHistoryClicked="msg => $emit('deletedHistoryClicked', msg)" />
          </template>
          <template v-slot:[`item.sysRegDt`]="{ item }">
            {{ $m(item.sysRegDt).format("yyyy-MM-DD HH:mm") }}
          </template>
        </v-data-table>

        <div class="text-center pt-2" v-if="pageCount > 0">
          <v-pagination v-model="page" :length="pageCount" color="secondary" :total-visible="$pagePV"></v-pagination>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import HistorySentence from "./HistorySentence.vue";
export default {
  components: { HistorySentence },
  props: ["works"],
  data() {
    return {
      tab: 0,
      headers: [
        { text: "WORKS", value: "type" },
        { text: "WORKED BY", value: "regUser", align: "center", cellClass: "font-gray2" },
        { text: "DATE", value: "sysRegDt", align: "center", cellClass: "font-gray2" },
      ],
      page: 1,
      itemsPerPage: 10,
      pageCount: 0,
    };
  },
  computed: {
    filteredWorks() {
      return this.tab === 0 ? this.works : this.works.filter(w => w.isMine === "Y");
    },
    isManager() {
      const cmpyUUID = this.$store.getters.getCmpyUUID;
      return (
        this.$store.state.user.companyList?.find(c => c.isUse === "Y" && c.cmpyUUID === cmpyUUID)?.userRole === "M"
      );
    },
  },
};
</script>

<style></style>

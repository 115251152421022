<template>
  <v-row class="dashboard">
    <v-col>
      <div class="page-title">
        <p class="title-1">Home</p>
      </div>

      <latest-update :works="works" class="latest-update" @deletedHistoryClicked="alert" />
    </v-col>
    <v-col>
      <works-calendar :works="myWorks" class="works-calendar" @deletedHistoryClicked="alert" />
    </v-col>

    <alert :show.sync="alertShow">
      {{ alertMsg }}
    </alert>

    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-row>
</template>

<script>
import LatestUpdate from "@/components/company/dashboard/LatestUpdate.vue";
import WorksCalendar from "@/components/company/dashboard/WorksCalendar.vue";
import codeHandler from "@/utils/codeHandler";
import Alert from "../common/cards/Alert.vue";

export default {
  components: { LatestUpdate, WorksCalendar, Alert },
  data() {
    return {
      loading: false,
      works: [],
      alertShow: false,
      alertMsg: "",
    };
  },
  methods: {
    getWorks() {
      this.loading = true;
      this.$http
        .get("/home/latest-update", { data: { cmpyUUID: this.$store.getters.getCmpyUUID } })
        .then(res => {
          if (res.data.status === 200) {
            this.works = res.data.data;
          } else codeHandler(res.data);
        })
        .finally(() => (this.loading = false));
    },
    alert(msg) {
      this.alertMsg = msg;
      this.alertShow = true;
    },
  },
  computed: {
    myWorks() {
      return this.works.filter(w => w.isMine === "Y");
    },
  },
  created() {
    this.getWorks();

    this.$watch(
      () => this.$route.params,
      () => {
        this.getWorks();
      },
    );
  },
};
</script>

<style></style>
